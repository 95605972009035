<template>
    <div class="productcard">
        <div class="details">
            <div class="img-wrapper">
                <img :src="img ? img : ''" :alt="name" />
                <div class="best-seller-tag" v-if="bestSeller">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        class="best-seller"
                    >
                        <g
                            id="Group_2022"
                            data-name="Group 2022"
                            transform="translate(-1069.428 -736)"
                        >
                            <g
                                id="Polygon_46"
                                data-name="Polygon 46"
                                class="cls-1"
                                transform="translate(1069.428 736)"
                            >
                                <path
                                    class="cls-3"
                                    d="M7.5,0a7.5,7.5,0,0,1,5.3,2.2h0A7.5,7.5,0,0,1,15,7.5h0a7.5,7.5,0,0,1-2.2,5.3h0A7.5,7.5,0,0,1,7.5,15h0a7.5,7.5,0,0,1-5.3-2.2h0A7.5,7.5,0,0,1,0,7.5H0A7.5,7.5,0,0,1,2.2,2.2h0A7.5,7.5,0,0,1,7.5,0Z"
                                />
                                <path
                                    class="cls-4"
                                    d="M 7.5 1 C 5.763799667358398 1 4.131500244140625 1.676119804382324 2.903809547424316 2.903809547424316 C 1.676119804382324 4.131500244140625 1 5.763790130615234 1 7.5 C 1 9.236209869384766 1.676119804382324 10.86849975585938 2.903809547424316 12.09618949890137 C 4.131500244140625 13.32388019561768 5.763790130615234 14 7.5 14 C 9.236209869384766 14 10.86849975585938 13.32388019561768 12.09618949890137 12.09618949890137 C 13.32388019561768 10.86849975585938 14 9.236209869384766 14 7.5 C 14 5.763790130615234 13.32388019561768 4.131500244140625 12.09618949890137 2.903809547424316 C 10.86849975585938 1.676119804382324 9.236209869384766 1 7.5 1 M 7.5 0 C 9.489130020141602 0 11.39678001403809 0.7901802062988281 12.80329990386963 2.196700096130371 C 14.20981979370117 3.603219985961914 15 5.510880470275879 15 7.5 C 15 9.489130020141602 14.20981979370117 11.39678001403809 12.80329990386963 12.80329990386963 C 11.39678001403809 14.20981979370117 9.489130020141602 15 7.5 15 C 5.510880470275879 15 3.603219985961914 14.20981979370117 2.196700096130371 12.80329990386963 C 0.7901802062988281 11.39678001403809 0 9.489120483398438 0 7.5 C 0 5.510869979858398 0.7901802062988281 3.603219985961914 2.196700096130371 2.196700096130371 C 3.603230476379395 0.7901802062988281 5.510880470275879 0 7.5 0 Z"
                                />
                            </g>
                            <g
                                id="Group_1981"
                                data-name="Group 1981"
                                transform="translate(21228 -3942)"
                            >
                                <path
                                    id="Polygon_45"
                                    data-name="Polygon 45"
                                    class="cls-2"
                                    d="M4,0,5.2,2.686l2.8.37L5.942,5.086,6.472,8,4,6.569,1.528,8l.53-2.914L0,3.056l2.8-.37Z"
                                    transform="translate(-20155 4681)"
                                />
                            </g>
                        </g>
                    </svg>

                    Popular
                </div>
            </div>
            <div class="info">
                <p class="product-name">{{ name }}</p>
                <div v-if="currentPrice" class="product-price">
                    <p
                        class="current-price"
                        v-if="currentPrice != 0"
                        :class="requiredDomain == false ? 'blur' : ''"
                    >
                        ₹ {{ convertToIndianNumberSystem(currentPrice) }}
                        <span class="mrp" v-if="originalPrice">
                            MRP:
                            <span class="original-price">
                                ₹ {{ originalPrice }}</span
                            >
                        </span>
                    </p>
                </div>
                <div v-else class="not-available-text">not available</div>
            </div>
            <div class="info-mobile">
                <div class="product-details">
                    <p class="product-vendor">{{ vendor }}</p>
                    <p
                        class="current-price"
                        v-if="currentPrice != 0"
                        :class="requiredDomain == false ? 'blur' : ''"
                    >
                        ₹
                        {{ convertToIndianNumberSystem(currentPrice) }}
                        <span class="mrp" v-if="originalPrice">
                            MRP:
                            <span class="original-price">
                                ₹ {{ originalPrice }}</span
                            >
                        </span>
                    </p>
                </div>
                <p class="product-name">{{ name }}</p>
            </div>
            <template v-if="!hideAction">
                <Button
                    v-if="replace == true"
                    white
                    name="Replace"
                    @click.native="$emit('replace', productObj, $event.target)"
                    :data-id="productId"
                    class="replace-btn"
                />
                <Button
                    v-else
                    white
                    name="Add Product"
                    @click.native="$emit('add', productObj, $event.target)"
                    :data-id="productId"
                    class="replace-btn"
                />
            </template>
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'ProductItem',
    components: { Button },
    mixins: [ConvertToPrice],
    props: {
        productObj: Object,
        hideAction: Boolean,
        productId: Number,
        img: String,
        name: String,
        currentPrice: Number,
        originalPrice: String,
        replace: Boolean,
        vendor: String,
        requiredDomain: Boolean,
        bestSeller: Boolean,
    },
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
@import './ProductItem.scss';
</style>
