<template>
    <div class="product-list-page" v-if="Object.keys(filters).length > 0">
        <div class="product-data" @scroll="onScroll">
            <div
                v-if="windowWidth < 1100"
                class="filters-mobile"
                @click="showMobileFilters"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.385"
                    height="15"
                    viewBox="0 0 25.385 15"
                >
                    <g
                        id="Group_2166"
                        data-name="Group 2166"
                        transform="translate(-308.125 -113.763)"
                    >
                        <g
                            id="noun-filter-4846914"
                            transform="translate(308.125 126.77) rotate(-90)"
                        >
                            <path
                                id="Path_2893"
                                data-name="Path 2893"
                                class="cls-1"
                                d="M.75,25.385A.733.733,0,0,1,0,24.669V.716A.733.733,0,0,1,.75,0,.733.733,0,0,1,1.5.716V24.669A.733.733,0,0,1,.75,25.385Z"
                                transform="translate(0 0)"
                            />
                            <path
                                id="Path_2895"
                                data-name="Path 2895"
                                class="cls-1"
                                d="M.75,25.385a.86.86,0,0,1-.75-.94V.94A.86.86,0,0,1,.75,0,.86.86,0,0,1,1.5.94V24.445A.86.86,0,0,1,.75,25.385Z"
                                transform="translate(9.129 0)"
                            />
                        </g>
                        <circle
                            id="Ellipse_613"
                            data-name="Ellipse 613"
                            class="cls-1"
                            cx="3.161"
                            cy="3.161"
                            r="3.161"
                            transform="translate(324.229 113.763)"
                        />
                        <circle
                            id="Ellipse_614"
                            data-name="Ellipse 614"
                            class="cls-1"
                            cx="2.696"
                            cy="2.696"
                            r="2.696"
                            transform="translate(313.735 123.371)"
                        />
                    </g>
                </svg>
                Filter
            </div>
            <div v-if="showMobileFilter && filters" class="filter-modal">
                <ProductFilterMobile
                    v-if="filters && windowWidth < 1100"
                    :filters="filters"
                    @filter="getFilteredProduct"
                    @closeFilters="showMobileFilter = false"
                    :selectedMobileFilters="selectedFilters"
                    :selectedVendorID="Number(this.$route.query.brand_id)"
                />
            </div>
            <p class="total-products" v-if="windowWidth > 1100">
                Total Product: {{ productsList.total_records }}
            </p>
            <div class="productlist-layout">
                <div class="sidebar">
                    <div class="sidebar-item">
                        <div class="filters" v-if="filters">
                            <ProductFilters
                                :filters="filters"
                                @filter="getFilteredProduct"
                                v-if="windowWidth > 1100 && filters"
                                :selectedVendorID="
                                    Number(this.$route.query.brand_id)
                                "
                                :prices="
                                    filterObject
                                        ? filterObject.price
                                        : undefined
                                "
                            />

                            <!-- :categories="
                                    filterObject
                                        ? filterObject.categorisation
                                              .category_groups
                                        : undefined
                                "
                                :prices="
                                    filterObject
                                        ? filterObject.price
                                        : undefined
                                " -->
                        </div>
                    </div>
                </div>
                <div class="main">
                    <div class="productlist-head">
                        <SearchAutoComplete
                            @search="fetchSuggestions"
                            @loadMoreSearchOptions="loadMoreSearchOptions"
                            :suggestionsArr="suggestions"
                        >
                            <template #suggestion="{suggestion}">
                                <router-link
                                    :to="{
                                        name: 'ProductDetailsPage',
                                        params: {
                                            id: suggestion.product_id,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    <div class="autocomplete-product">
                                        <div class="autocomplete-product-img">
                                            <img :src="suggestion.image" />
                                        </div>
                                        <div
                                            class="autocomplete-product-detail"
                                        >
                                            <p>{{ suggestion.name }}</p>
                                            <div class="autocomplete-category">
                                                <span>
                                                    {{
                                                        suggestion.item_category
                                                    }}
                                                </span>
                                                <template
                                                    v-if="
                                                        suggestion.item_subcategory
                                                    "
                                                >
                                                    <span>
                                                        <svg
                                                            width="25"
                                                            height="24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                                                fill="#000"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span>
                                                        {{
                                                            suggestion.item_subcategory
                                                        }}
                                                    </span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </template>
                        </SearchAutoComplete>
                    </div>
                    <div
                        class="filters-applied"
                        v-if="filterObject && windowWidth > 1100"
                    >
                        <div
                            v-for="vendor in filterObject.vendor"
                            :key="vendor"
                            class="vendors-applied"
                        >
                            <template
                                v-for="vendors in filters[0].filters[0].data"
                            >
                                <div
                                    v-if="vendor == vendors.id"
                                    :key="vendors.id"
                                    class="check"
                                    @click="undoFilter('vendor', vendor)"
                                >
                                    <p>{{ vendors.name }}</p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        fill="#94969f"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            class="cls1"
                                            d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                        />
                                    </svg>
                                </div>
                            </template>
                        </div>
                        <template v-if="filterObject.categorisation">
                            <div
                                v-for="category in filterObject.categorisation
                                    .category_groups"
                                :key="category"
                                class="vendors-applied"
                            >
                                <template
                                    v-for="categories in filters[0].filters[1]
                                        .data"
                                >
                                    <div
                                        v-if="category == categories.id"
                                        :key="categories.id"
                                        class="check"
                                        @click="
                                            undoFilter('category', category)
                                        "
                                    >
                                        <p>{{ categories.name }}</p>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            fill="#94969f"
                                            viewBox="0 0 25 24"
                                        >
                                            <path
                                                class="cls1"
                                                d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                            />
                                        </svg>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div
                            v-for="(prop, idx) in filterObject.properties"
                            :key="prop"
                            class="vendors-applied"
                        >
                            <div
                                v-for="(item, index) in prop.values"
                                :key="item"
                            >
                                <div
                                    v-for="props in filters[1].filters"
                                    :key="props.id"
                                >
                                    <template v-for="value in props.data">
                                        <div
                                            v-if="item == value.id"
                                            :key="value.id"
                                            class="check"
                                            @click="
                                                undoFilter(
                                                    'property',
                                                    idx,
                                                    index,
                                                )
                                            "
                                        >
                                            <p>{{ value.name }}</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                fill="#94969f"
                                                viewBox="0 0 25 24"
                                            >
                                                <path
                                                    class="cls1"
                                                    d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                                />
                                            </svg>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <template v-if="filterObject.price">
                            <div
                                v-for="(price, index) in filterObject.price"
                                :key="price"
                                class="vendors-applied"
                            >
                                <template
                                    v-for="prices in filters[0].filters[2].data"
                                >
                                    <div
                                        v-if="price.max == prices.max"
                                        :key="prices.id"
                                        class="check"
                                        @click="
                                            undoFilter('price', prices, index)
                                        "
                                    >
                                        <p>{{ prices.name }}</p>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            fill="#94969f"
                                            viewBox="0 0 25 24"
                                        >
                                            <path
                                                class="cls1"
                                                d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                            />
                                        </svg>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                    <template v-if="similarProducts.length > 0">
                        <div
                            v-if="productLoader === false"
                            class="cards-container"
                        >
                            <div
                                v-for="product in similarProducts"
                                :key="product.id"
                            >
                                <router-link
                                    :to="{
                                        name: 'ProductDetailsPage',
                                        params: {
                                            id: product.product_id,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    <ProductItem
                                        :product-obj="product"
                                        :vendor="product.vendor"
                                        :product-id="product.product_id"
                                        :hideAction="true"
                                        :img="
                                            product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png')
                                        "
                                        :name="product.name"
                                        :current-price="product.item_price"
                                        :requiredDomain="requiredDomain"
                                        :bestSeller="product.bestseller"
                                    />
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="no-products-found"
                            style="    width: 300px; text-align:center;margin: auto;"
                        >
                            <img src="@/assets/images/no-results-found.png" />
                            <p>No Products Available</p>
                        </div>
                    </template>

                    <div class="loader-container">
                        <div class="spinner"></div>
                    </div>

                    <div v-if="productLoader === true" class="wait2">
                        <Loader />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CustomisationPageModule from 'store/modules/CustomisationPage'
import ProductListPageModule from 'store/modules/ProductListPage'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ProductItem from 'componentsv2/ProductItem'
import ConvertToPrice from 'mixins/ConvertToPrice'
import ProductFilters from 'componentsv2/ProductFilters'
import ProductFilterMobile from 'componentsv2/ProductFilterMobile'
import SearchAutoComplete from 'componentsv2/SearchAutoComplete'
import Loader from 'componentsv2/Loader'
export default {
    name: 'ProductListPage',
    components: {
        ProductItem,
        ProductFilters,
        SearchAutoComplete,
        ProductFilterMobile,
        Loader,
    },
    props: {
        requiredDomain: Boolean,
    },
    mixins: [RegisterStoreModule, ConvertToPrice],
    data() {
        return {
            filter_str: '',
            pageNumber: 1,
            totalNumberOfPages: '',
            similarProducts: null,
            suggestions: [],
            //paging for search products
            searchNumber: 1,
            totalNumberOfSearchPages: '',
            searchingActive: true,
            autoLoad: true,
            showMobileFilter: false,
            selectedFilters: [],
            loaderShow: false,
            filterObject: {},
            productLoader: false,
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.AppModule.windowWidth,
            filters: state => state.ProductListPage.filters_data,
            productsList: state => state.ProductListPage.productsList,
        }),
    },
    created() {
        this.registerStoreModule('CustomisationPage', CustomisationPageModule)
        this.registerStoreModule('ProductListPage', ProductListPageModule)
        let obj
        if (this.$route.query.brand_id) {
            obj = {
                filters: {
                    vendor: [this.$route.query.brand_id],
                },
                page: 1,
                size: 20,
            }
        } else {
            obj = {
                filters: {},
                page: 1,
                size: 20,
            }
        }
        this.fetchFilter()
        this.loaderShow = true
        this.fetchAllProducts(obj).then(response => {
            if (response.responseCode == 200) {
                this.loaderShow = false
                this.totalNumberOfPages = response.payload.total_page
                this.similarProducts = response.payload.products
                if (this.windowWidth < 1100) {
                    this.filterObject.vendor.push(
                        Number(this.$route.query.brand_id),
                    )
                }
            }
        })
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    methods: {
        ...mapActions({
            fetchFilter: 'fetchFilter',
            fetchAllProducts: 'fetchAllProducts',
            fetchAllSearchedProducts: 'fetchAllSearchedProducts',
            fetchProducts: 'fetchProducts',
            updateRelatedProducts: 'updateRelatedProducts',
        }),
        toggleReplaceProductModal() {
            this.$emit('toggleReplaceProductModal')
        },
        onScroll(e) {
            //If last element reached then load api for more products
            if (
                document.documentElement.scrollTop + window.innerHeight >=
                document.documentElement.offsetHeight - 300
            ) {
                this.loadMoreProducts()
            }
        },
        undoFilter(type, value, id) {
            switch (type) {
                case 'vendor':
                    this.filterObject.vendor.forEach((element, index) => {
                        if (element == value) {
                            this.filterObject.vendor.splice(index, 1)
                        }
                    })
                    break
                case 'category':
                    this.filterObject.categorisation.category_groups.forEach(
                        (element, index) => {
                            if (element == value) {
                                this.filterObject.categorisation.category_groups.splice(
                                    index,
                                    1,
                                )
                            }
                        },
                    )
                    break
                case 'price':
                    this.filterObject.price.splice(id, 1)
                    this.selectedFilters.price.splice(id, 1)

                    break
                case 'property':
                    this.filterObject.properties.forEach((element, index) => {
                        if (index === value) {
                            element.values.forEach((ele, idx) => {
                                if (idx == id) {
                                    this.filterObject.properties[
                                        index
                                    ].values.splice(id, 1)
                                }
                            })
                        }
                    })
                    break
            }
        },
        getFilteredProduct(filter) {
            this.productLoader = true
            this.selectedFilters = filter
            this.filterObject = filter
            this.showMobileFilter = false
            this.pageNumber = 1
            this.fetchAllProducts({
                filters: filter,
                page: 1,
                size: 20,
            }).then(response => {
                if (response.responseCode == 200) {
                    this.productLoader = false
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.products
                }
            })
        },
        showMobileFilters() {
            this.showMobileFilter = !this.showMobileFilter
        },
        loadMoreProducts() {
            if (
                this.pageNumber < this.totalNumberOfPages &&
                this.autoLoad == true
            ) {
                let loaderContainer = document.querySelector(
                    '.loader-container',
                )
                loaderContainer.style.display = 'flex'
                this.autoLoad = false
                this.pageNumber++
                this.fetchAllProducts({
                    filters: this.filterObject,
                    page: this.pageNumber,
                    size: 20,
                }).then(response => {
                    if (response.responseCode == 200) {
                        // loaderContainer.removeChild(
                        //     document.querySelector('.spinner'),
                        // )
                        this.totalNumberOfPages = response.payload.total_page
                        response.payload.products.forEach(product => {
                            this.similarProducts.push(product)
                        })
                        setTimeout(() => {
                            this.autoLoad = true
                        }, 2500)
                        loaderContainer.style.display = 'none'
                    }
                })
            }
        },
        fetchSuggestions(query) {
            if (query.length > 2) {
                this.suggestions = []
                this.searchNumber = 1
                this.fetchAllSearchedProducts({
                    query: query,
                    filters: {},
                    page: 1,
                    size: 20,
                }).then(response => {
                    this.suggestions = response.payload.products
                    this.totalNumberOfSearchPages = response.payload.total_page
                })
            }
        },
        loadMoreSearchOptions(query) {
            if (
                this.searchNumber < this.totalNumberOfSearchPages &&
                this.searchingActive == true
            ) {
                this.searchingActive = false
                this.searchNumber++
                this.fetchAllSearchedProducts({
                    query: query,
                    filters: {},
                    page: this.searchNumber,
                    size: 20,
                }).then(response => {
                    response.payload.products.forEach(product => {
                        this.suggestions.push(product)
                    })
                    this.searchingActive = true
                    this.totalNumberOfSearchPages = response.payload.total_page
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import './ProductListPage.scss';
</style>
